/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';

import Styles from './layout.module.css';


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />

        <main>{children}</main>

        <footer className={Styles.footer}>
          <div className="container">

            <div className="row">
              <div className="col-sm">
                &copy;
                {' '}
                {new Date().getFullYear()}
                {' '}
                {data.site.siteMetadata.title}
              </div>

              <div className="col-sm">
                &nbsp;
              </div>

              <div className="col-sm">
                Powered by
                {' '}
                <a href="https://www.gatsbyjs.org">Gatsby</a>
,
                {' '}
                <a href="https://www.netlify.com/">Netlify</a>
              </div>
            </div>
          </div>
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

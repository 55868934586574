import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Styles from './header.module.css';

const Header = ({ siteTitle }) => (

  <header role="banner">

    <nav className="navbar navbar-dark bg-dark navbar-expand-md">
      <div className="container">
        <Link to="/" className={`navbar-brand ${Styles.bland}`}>
          {siteTitle}
        </Link>


        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/about" className="nav-link">
                About
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
